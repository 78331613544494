import React, { useState, useEffect, useReducer } from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
import Faq from "react-faq-component";
import { getDetails } from './Services/getDetails';
import { initialState, reducer } from './reducer';
import { useSelector } from 'react-redux';
import { useWebProperties } from '../../webProperties';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Layout from '../../../Optimisation/MetaData/Layout/Layout';
import Alert from '../../Alert';
import API from "../../Network/API";
const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

export default function FAQs() {

    const [state, dispatch] = useReducer(reducer, initialState);
    const webProperties = useSelector(state => state.landingReducer.webProperties)
    const properties = useSelector(state => state.landingReducer.properties)
    const [searchQuery, setSearchQuery] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);


    const { APP_NAME, IMG_SRC } = useWebProperties();
    useEffect(() => {
        getDetails(dispatch);
        getFaqsCategory();
    }, [])
    var rows = state.appDetails
        .filter(function (faq) {
            const matchesCategory = faq.category_id === selectedCategoryId;
            const matchesSearchQuery = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesCategory && matchesSearchQuery;
        })
        .map((faq) => ({
            title: `Q) ${faq.question}`,
            content: faq.answer,
        }));

    if (searchQuery.length > 0) {
        // eslint-disable-next-line
        var rows = state.appDetails
            .filter(function (faq) {

                const matchesSearchQuery = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(searchQuery.toLowerCase());

                return matchesSearchQuery;
            })
            .map((faq) => ({
                title: `Q) ${faq.question}`,
                content: faq.answer,
            }));
    }

    var details = { rows };
    // get categories
    const getFaqsCategory = async () => {
        try {
            var response = await API.post("faqs.php", {
                action: 'getCategory',
            }, {}, false);
            if (response.status === 'True') {

                setCategories(response.data);
            }
        } catch (error) {
            Alert(error.message)
        }
    }
    const handleCategoryClick = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };
    const handleBackButtonClick = () => {
        setSelectedCategoryId(null);
    };

    // const faqs = state.appDetails.filter(faq =>
    //     faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    //   console.log("in",details)


    // const data = {
    //     rows: [
    //         {
    //             title: " Q) How Can I invite people to join my Group? ",
    //             content: `You can go to add agile squad member and add a resource to your project.
    //             If you would like the squad member to be added to multiple projects, you can do it by selecting all the different projects you want to add them to.`,
    //         },
    //         {
    //             title: " Q) Why Agile2x7?",
    //             content:"It is very simple to use <br/>Task level collaboration for any size Team <br/> Prioritize what you and your team want to wok on and get the updates on a daily basis <br/> Follows Agile and Scrum project management <br/>Boards to show you the progress of the task <br/> Chat collaboration on user story level and more <br/> Whiteboards for collocated or offshore teams to work together from the inception of the project <br/> Share Documents, messages, architectures and ideas in one place <br/>Can be used by non IT prfessionals as well",
    //         },
    //         {
    //             title: "Q) Is there a discounted offer?",
    //             content: `Please contact our sales team, we will see how we can help. `,
    //         },
    //         {
    //             title: "Q) How can I change my plan?",
    //             content:"Go to your profile and click payment, you will see all the available options, and you can choose what you want.",
    //         },
    //         {
    //             title: "Q) How can I request features?",
    //             content:"Please go to support and place your request, or talk to our Sales team.",
    //         },
    //     ],
    // };

    return (
        <Layout pageName='faq'>
            <div>
                <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                    <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                        {/* eslint-disable-next-line */}
                        <a className="navbar-brand"><Link to={{ pathname: "/" }}>
                            {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
                            <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>
                        </a>
                        <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
                            {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
                            {/* eslint-disable-next-line */}
                            <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
                            {/* eslint-disable-next-line */}
                            <a style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
                            {/* support page button */}
                        </form>
                    </div>
                </nav>

                {selectedCategoryId === null ? (
                    <div>
                        <section id="section1">
                            <div className="container">
                                <div className="release-note mt-3">
                                    <div className="d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight text-center"><h2 className="mb-3">FAQ's</h2>
                                            <div className='d-flex justify-content-end'> <input className='form-control bt-n' type="search" placeholder="Search FAQs..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ width: "30%" }} /></div>
                                        </div></div>
                                </div>
                            </div>
                        </section>
                        {/* ---categories */}
                        <div className="container mt-3" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '5px' }}>
                            <div className="row">
                                {/*  */}
                                {/* if(searchQuery.length >0) */}
                                {(searchQuery.length > 0) ?

                                    (<div className="container">
                                        <div className="accordion" id="accordionExample">
                                            <Faq
                                                data={details}
                                                styles={styles}
                                                config={config}
                                            />
                                        </div>
                                    </div>) : ""}
                                {/*  */}
                                {searchQuery.length === 0 && categories.map(item => (
                                    <div className="col-md-6  col-12 mb-3" key={item.id}>
                                        <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px' }}>
                                            <h3 onClick={() => handleCategoryClick(item.id)} style={{ cursor: 'pointer' }}>{item.name}</h3>
                                        </div>
                                    </div>

                                ))}


                            </div>
                        </div>
                    </div>
                )
                    :
                    (<>
                        <section id="section1">
                            <div className="container">
                                <div className="release-note mt-3">
                                    <div className="d-flex bd-highlight">
                                        <div className="p-2 flex-grow-1 bd-highlight text-center"><h2 className="" style={{whiteSpace:'wrap'}}>{categories.find((cat) => cat.id === selectedCategoryId).name}</h2>


                                            {/* back button & search */}
                                            <div className='d-flex justify-content-between'>
                                                <button onClick={handleBackButtonClick} style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b', color: 'white' }}>Back</button>
                                                <input className='form-control bt-n' type="search" placeholder="Search FAQs..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ width: "30%" }} /></div>
                                        </div></div>
                                </div>
                            </div>
                        </section>
                        <div>
                            {/* Render content related to selected category */}
                            {/* <p>Category ID: {selectedCategoryId}</p>
          <p>Category Name: {categories.find((cat) => cat.id === selectedCategoryId).name}</p> */}
                            {/* Add your code to display content related to the selected category */}
                            <div className="container mt-3">
                                <div className="accordion p-2" id="accordionExample">
                                    <Faq
                                        data={details}
                                        styles={styles}
                                        config={config}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    )
                }
                {/* <div className="container mt-3" style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '5px'}}>
                <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px'}}>
                            <Link to={{ pathname: "/getStartFaq" }}>
                                <h3>Getting Started with WeTasker</h3>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px'}}>
                            <Link to={{ pathname: "/myWeTasker" }}>
                                <h3>My WeTasker account</h3>
                                </Link>
                            </div>
                        </div>
                </div>
                <div className="row">
                        <div  className="col-md-6 mb-3">
                            <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px'}}>
                            <Link to={{ pathname: "/usingWeTasker" }}>
                                <h3>Using WeTasker</h3>
                                </Link>
                            </div>
                        </div>
                        <div  className="col-md-6 mb-3">
                            <div className="faq-category" style={{ backgroundColor: 'white', padding: '40px'}}>
                            <Link to={{ pathname: "/billingAndLicensing" }}>
                                <h3>Billing & Licensing</h3>
                                </Link>
                            </div>
                        </div>
                </div>
            </div> */}

                <div style={{ marginTop: '200px' }}></div>
                <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                    <div style={{ color: 'white' }}>
                        {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
                    </div>
                </footer>
            </div>
        </Layout>
    )
}
